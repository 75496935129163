
import { defineComponent, Ref, ref, watch } from 'vue';
import basicInformation from '@/views/pm/apartment/info/basic-information.vue';
import devices from '@/views/pm/apartment/info/devices.vue';
import residents from '@/views/pm/apartment/info/residents.vue';
import { apartment, community } from '@/data';
import {
    ListData, DevicesData, ResidentData,
    ApartmentInfoData
} from '@/views/pm/apartment/info/all.type';
import { ApartmentForm } from '@/components/view/pm/apartment-edit-dialog';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { propertyBaseRouter, propertySubRouter } from '@/router';
import { getDeviceOwnerList } from '@/api/community/device';
import { getFamilyAppList } from '@/api/community/user';
import {
    basicInformationInit, devicesInit, getBasicInformationList, residentInit
} from './util';

const breadCrumb = [{
    label: WordList.ProperAllTextApartments,
    path: `/${propertyBaseRouter}/${propertySubRouter.apartment}`
},
{
    label: WordList.ProperAllTextInformation
}];
export default defineComponent({
    props: {
        ID: {
            type: String,
            required: true
        }
    },
    components: {
        basicInformation,
        devices,
        residents,
        propertyBreadCrumb
    },
    setup(props) {
        const basicInformationData: Ref<Array<ListData>> = basicInformationInit();
        const residentData: Ref<Array<ResidentData> | []> = residentInit();
        const devicesData: Ref<Array<DevicesData> | []> = devicesInit();
        const basicInformationEditForm: Ref<ApartmentForm | {}> = ref({});
        watch(community.isOpenCommunalFee, (val) => {
            getInfo();
        });
        const getInfo = () => {
            apartment.getApartmentInfoFromID({ ID: props.ID }, (res: ApartmentInfoData) => {
                const apartmentInfoData: ApartmentInfoData = { ...res };
                basicInformationEditForm.value = apartmentInfoData;
                basicInformationData.value = getBasicInformationList(apartmentInfoData);
            });
            getDeviceOwnerList({
                ID: props.ID
            }, (res: DevicesData[]) => {
                devicesData.value = res;
            });
            getFamilyAppList({
                ID: props.ID
            }, (res: ResidentData[]) => {
                residentData.value = res;
            });
        };
        getInfo();
        return {
            basicInformationData,
            devicesData,
            residentData,
            getInfo,
            basicInformationEditForm,
            breadCrumb
        };
    }
});
