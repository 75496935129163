import { ref, watch, Ref } from 'vue';
import { community } from '@/data';
import { BasicInformationData } from '@/views/pm/apartment/info/all.type';

export default null;

const basicInformationInit = () => ref([{ name: '', label: '', value: '' }]);

const residentInit = () => ref([{
    CreateTime: '',
    Email: '',
    MobileNumber: '',
    Name: '',
    Role: '',
    Mark: ''
}]);

const devicesInit = () => ref([{
    Location: '',
    MAC: '',
    Status: 0,
    Type: 0
}]);
const callTypeList: Ref<Array<string>> = ref([
    WordList.ProperAllTextSmartPlusIndoor,
    WordList.ProperAllTextPhoneIndoor,
    WordList.ProperAllTextSmartPlusIndoorBackup,
    WordList.ProperAllTextIndoorSmartPlusBackup,
    WordList.ProperAllTextIndoorPhoneBackup,
    WordList.ProperAllTextIndoorSmartPlusPhone
]);
const enableIpDirectList: Array<string> = [
    WordList.ProperAllTextSIPCall,
    WordList.ProperAllTextIPCall
];

// 高级功能显示
const getComPermission = (name: string, limit: number, permission: number) => {
    if (limit === 0 && name === 'TempKeyPermission') {
        return true;
    }
    if (limit === 0 && name === 'FamilyMemberControl') {
        return false;
    }
    if (limit === 0 && name === 'RegisterFaceControl') {
        return true;
    }
    return permission === 1;
};

const getBasicInformationList = (data: BasicInformationData) => {
    const basicInformationList: Array<{
        name: keyof BasicInformationData;
        label: string;
        value: string;
    }> = SERVER_LOCATION === 'az' && community.isOpenCommunalFee.value ? [
        {
            name: 'Building',
            label: WordList.DeviceDetailDetailUnitName,
            value: ''
        }, {
            name: 'AptNumber',
            label: WordList.RDeviceSearchLabelRoomName,
            value: ''
        }, {
            name: 'AptName',
            label: WordList.RDeviceSearchLabelRoomNumber,
            value: ''
        }, {
            name: 'Area',
            label: WordList.AreaSquareMeter,
            value: ''
        }, {
            name: 'FinalCommunalFee',
            label: WordList.CommunalFeeOfPerMonth,
            value: ''
        }, {
            name: 'CallType',
            label: WordList.ProperAllTextCallType,
            value: ''
        }, {
            name: 'EnableIpDirect',
            label: WordList.ProperAllTextSipIpCall,
            value: ''
        }, {
            name: 'TempKeyPermission',
            label: WordList.ProperAllTextCreatQRCodes,
            value: ''
        }, {
            name: 'RegisterFaceControl',
            label: WordList.RegisterFaceID,
            value: ''
        }, {
            name: 'FamilyMemberControl',
            label: WordList.ProperAllTextFamilyMemberLimit,
            value: ''
        }
    ] : [
        {
            name: 'Building',
            label: WordList.DeviceDetailDetailUnitName,
            value: ''
        }, {
            name: 'AptNumber',
            label: WordList.RDeviceSearchLabelRoomName,
            value: ''
        }, {
            name: 'AptName',
            label: WordList.RDeviceSearchLabelRoomNumber,
            value: ''
        }, {
            name: 'CallType',
            label: WordList.ProperAllTextCallType,
            value: ''
        }, {
            name: 'EnableIpDirect',
            label: WordList.ProperAllTextSipIpCall,
            value: ''
        }, {
            name: 'TempKeyPermission',
            label: WordList.ProperAllTextCreatQRCodes,
            value: ''
        }, {
            name: 'RegisterFaceControl',
            label: WordList.RegisterFaceID,
            value: ''
        }, {
            name: 'FamilyMemberControl',
            label: WordList.ProperAllTextFamilyMemberLimit,
            value: ''
        }
    ];

    basicInformationList.forEach((param, index) => {
        if (param.name === 'CallType') {
            basicInformationList[index].value = callTypeList.value[data[param.name]];
        } else if (param.name === 'EnableIpDirect') {
            basicInformationList[index].value = enableIpDirectList[data[param.name]];
        } else if (param.name === 'TempKeyPermission') {
            basicInformationList[index].value = getComPermission(param.name, data.LimitCreateQRcode, data[param.name])
                ? WordList.ProperAllTextAllowed : WordList.ProperAllTextNotAllowed;
        } else if (param.name === 'FamilyMemberControl') {
            basicInformationList[index].value = getComPermission(param.name, data.LimitFamilyMember, data[param.name])
                ? WordList.ProperAllTextOn : WordList.ProperAllTextOff;
        } else if (param.name === 'RegisterFaceControl') {
            basicInformationList[index].value = getComPermission(param.name, data.LimitRegisterFace, data[param.name])
                ? WordList.ProperAllTextAllowed : WordList.ProperAllTextNotAllowed;
        } else if (param.name === 'FinalCommunalFee') {
            basicInformationList[index].value = `₼ ${data[param.name].toString()}`;
        } else {
            basicInformationList[index].value = data[param.name] === null ? '' : data[param.name].toString();
        }
    });

    return basicInformationList;
};

export {
    basicInformationInit,
    residentInit,
    devicesInit,
    getBasicInformationList
};
