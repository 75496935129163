
import {
    defineComponent, PropType, ref, watch
} from 'vue';
import { list, ListHeaderItem, filterEmptyCellValue } from '@/components/common/list';
import { ResidentData } from '@/views/pm/apartment/info/all.type';
import tip from '@/components/common/tip';
import { access } from '@/data';
import { limitErrorImg } from '@/methods/basicFun';

const headers: Array<ListHeaderItem> = [{
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName,
    type: 'customize'
}, {
    name: 'ContactDetail',
    label: WordList.ProperAllTextContactInformation,
    type: 'customize'
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];

export default defineComponent({
    components: {
        list,
        tip
    },
    props: {
        residentsList: {
            type: Object as PropType<Array<ResidentData>>,
            required: true
        }
    },
    setup(props) {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };
        const updateToList = ref(0);
        watch(
            () => props.residentsList,
            () => {
                updateToList.value += 1;
            }
        );

        const ContactDetailIcons = {
            phone: require('@/assets/image/phone.png'),
            email: require('@/assets/image/email.png')
        };

        const MasterStatusIcons = {
            Activation: require('@/assets/image/Activation.png'),
            Inactivated: require('@/assets/image/Inactivated.png'),
            Expired: require('@/assets/image/Expired.png')
        };

        return {
            updateToList,
            headers,
            filterEmptyCellValue,
            ContactDetailIcons,
            MasterStatusIcons,
            access,
            setAllImg
        };
    }
});
